import React, { useState } from 'react'
import './Gender.css'

import manPicActive from '../../assets/man.png'
import manPicInActive from '../../assets/man01.png'

import womanPicActive from '../../assets/woman.png'
import womanPicInActive from '../../assets/woman01.png'

const GenderDropDown = ({ text, onChange, required, error }) => {
    const [value, setValue] = useState('')

    const manImg = () => {
        return value === 'ชาย' ? manPicActive : manPicInActive
    }

    const womanImg = () => {
        return value === 'หญิง' ? womanPicActive : womanPicInActive
    }

    const manTextCss = () => {
        return value === 'ชาย' ? 'text active' : 'text'
    }

    const womanTextCss = () => {
        return value === 'หญิง' ? 'text active' : 'text'
    }

    const setKey = (value) => {
        setValue(value)
        onChange(value)
    }

    return (<>
        <div className={`content-area ${(error && required) ? 'error' : ''}`}>
            <div className="gender-item" onClick={(e) => { setKey('ชาย') }}>
                <img src={manImg()} className="icon-size" alt='man' />
                <div className={manTextCss()}>ชาย</div>
            </div>
            <div className="gender-item" onClick={(e) => { setKey('หญิง') }}>
                <img src={womanImg()} className="icon-size" alt='woman' />
                <div className={womanTextCss()}>หญิง</div>
            </div>
        </div>
    </>)
}


export default GenderDropDown