import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Test from './pages/test';
import reportWebVitals from './reportWebVitals';
import NoMatch from './NoMatch'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJs from '@date-io/dayjs'
import dayJs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra' //('dayjs/plugin/buddhistEra')
import { Provider } from 'react-redux'
import store from './store'

import ReactGA from 'react-ga';

dayJs.extend(buddhistEra)
// dayJs.locale('th')

ReactGA.initialize('UA-170617186-1', { gaOptions: { name: 'LMS' } });
const theme = createMuiTheme({
  root: {
    fontFamily: ['SukhumvitSet', 'sans-serif'].join(',')
  },
  typography: {
    fontFamily: ['SukhumvitSet', 'sans-serif'].join(',')
  }
});


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <MuiPickersUtilsProvider utils={DayJs} locale={'th'} yearFormat={"BBBB"} >
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/:id">
                <App />
              </Route>
              {/* <Route exact path="/page/test">
                <Test />
              </Route> */}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
          <div style={{ paddingTop: '30px', paddingBottom: '10px', textAlign: 'center', fontSize: '0.8em' }}>© 2023 SCB PROTECT  |  <a id="ot-sdk-btn" class="ot-sdk-show-settings">ตั้งค่าคุ้กกี้</a></div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
