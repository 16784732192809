import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import './BasicDropdown.css';


const CssTextField = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#7B25F6',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiInputBase-root::before': {
            border: 'none',
            borderBottomColor: '#6F42CE'
        },
        '& .MuiFilledInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7B25F6',
            },
        },
    },
})(TextField);

const BasicDropdown = ({ required, label, value, onChange, dataset, error, helperText }) => {
    return (
        <FormControl variant="filled" style={{ width: '100%' }} error={error} required={required}>
            <Autocomplete
                options={dataset}
                getOptionLabel={(option) => option.name || ""}
                getOptionSelected={(option, value) => {
                    return option.id === value.id
                }}
                onChange={onChange}
                value={value}
                renderInput={(params) =>
                    <CssTextField
                        {...params}
                        required={required}
                        label={label}
                        error={error}
                        variant="filled"
                    />}
            />
            {error ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl >
    )
}

export default BasicDropdown

