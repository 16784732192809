import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const CssCheckBox = withStyles({
    root: {
        margin: '0 0 auto 0',
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&$checked': {
            color: '#7B25F6'
        },
        '&$checked:hover': {
            backgroundColor: 'transparent'
        }
    },
    checked: {},
})(Checkbox);



const Consent = ({ value, text, onChange, required }) => {
    return (<>
        <div style={{ display: 'flex' }}>
            <FormControlLabel
                style={{ display: 'block' }}
                required={required}
                value={value}
                onChange={onChange}
                control={<CssCheckBox />}
                // label={text}
                labelPlacement="end"
            />
            <div className="consent-text" dangerouslySetInnerHTML={{ __html: text }}>
            </div>
        </div>
    </>)
}

export default Consent