import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MaskedInput from '@biproxi/react-text-mask';

const CssTextField = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#7B25F6',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiInputBase-root::before': {
            border: 'none',
            borderBottomColor: '#6F42CE'
        },
        '& .MuiFilledInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7B25F6',
            },
        },
    },
})(TextField);

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
        {...other}
        style={{ width: '100%' }}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
    
        />
    );
}

const InputMobileReadOnly = ({ required, label, value, error, onChange, helperText }) => {
    return (
        <>
            <CssTextField
                required={required}
                label={label}
                value={value}
                error={error}
                onChange={onChange}
                helperText={error ? helperText : ''}
                variant="filled"
                id={label}
                InputProps={{
                    inputComponent: TextMaskCustom,
                    readOnly: true,
                }}
            />
        </>
    )
}


export default InputMobileReadOnly