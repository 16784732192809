import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const CssTextField = withStyles({
    root: {
        visibility:'hidden', 
        height:'0px',
        '& .MuiFilledInput-input': {
            padding: '0px'
        }
        // '& label.Mui-focused': {
        //     color: '#7B25F6',
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: '#6F42CE',
        // },
        // '& .MuiFilledInput-underline:after': {
        //     borderBottomColor: '#6F42CE',
        // },
        // '& .MuiInputBase-root::before': {
        //     border: 'none',
        //     borderBottomColor: '#6F42CE'
        // },
        // '& .MuiFilledInput-underline.Mui-error:after': {
        //     borderBottomColor: '#f44336 !important'
        // },
        // '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //         borderColor: 'red',
        //     },
        //     '&:hover fieldset': {
        //         borderColor: 'yellow',
        //     },
        //     '&.Mui-focused fieldset': {
        //         borderColor: '#7B25F6',
        //     },
        // },
    },
})(TextField);


const HiddenField = ({ required, label, value, error, helperText,onChange }) => {
    return (
        <>
            <CssTextField
                required={required? required:false}
                label={label?label:""}
                value={value}
                error={error}
                helperText={error ? helperText : ''}
                onChange={onChange}
                variant="filled"
            />
        </>
    )
}

export default HiddenField