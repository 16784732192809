import React from 'react'
import Banner from '../../assets/bg-lms.png'
import './HeaderLayer.css'

const HeaderLayer = () => {
    return (<>
        <header className="lms-header-layer" >
            <img src={Banner} className="lms-header-layer" alt="header drop lead"/>
        </header>
    </>)
}

export default HeaderLayer