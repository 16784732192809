import "./App.css";
import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Zoom from "@material-ui/core/Zoom";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import dayjs from "dayjs";

import CheckBox from "./components/CheckBox/CheckBox";
import HeaderLayer from "./components/HeaderLayer/HeaderLayer";
import FormHeader from "./components/FormHeader/FormHeader";
import InputText from "./components/InputText/InputText";
import InputNumber from "./components/InputNumber/InputNumber";
import InputTextReadOnly from "./components/InputTextReadOnly/InputTextReadOnly";
import InputCalendar from "./components/InputCalendar/InputCalendar";
import InputCalendarReadOnly from "./components/InputCalendarReadOnly/InputCalendarReadOnly";
import InputCalendarEng from "./components/InputCalendarEng/InputCalendarEng";
import InputMobile from "./components/InputMobile/InputMobile";
import InputMobileReadOnly from "./components/InputMobileReadOnly/InputMobileReadOnly";
import InputEmail from "./components/InputEmail/InputEmail";
import InputEmailReadOnly from "./components/InputEmailReadOnly/InputEmailReadOnly";
import GenderDropDown from "./components/Gender/Gender";
import LmsButton from "./components/LmsButton/LmsButton";
import BasicDropdown from "./components/BasicDropdown/BasicDropdown";
import BasicDropdownReadOnly from "./components/BasicDropDownReadOnly/BasicDropdownReadOnly";
import IdCard from "./components/IdCard/IdCard";
import IdCardReadOnly from "./components/IdCardReadOnly/IdCardReadOnly";
import PlainText from "./components/PlainText/PlainText";
import Modal from "./components/Modal/Modal";
import Otp from "./components/Otp/index";
import Loading from "./components/Loading/index";
import HiddenField from "./components/HiddenField/HiddenField";
import InterestProductV2, { GenerateInterestedProductV2ComponentObjectForm, InterestedProductV2Type, InterestedProductV2Validated, InterestedProductFinalObjectData } from "./components/InterestedProductV2";

import { useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import FormApi from "./api/FormApi";
import thaiIdCard from "thai-id-card";
import correct from "./assets/correct.png";
import sfAreaData from "./data/sf_area.json";
import occupationData from "./data/occupation.json";
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const contact_itme_list = [
  "8.00 - 10.00",
  "10.00 - 12.00",
  "12.00 - 14.00",
  "14.00 - 16.00",
  "16.00 - 18.00",
  "18.00 - 20.00",
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  let match = useRouteMatch("/:id");
  let query = useQuery();
  const history = useHistory("/");
  const [loading, setLoading] = useState(true);
  const [subbmitting, setSubbmitting] = useState(false);
  const [consent, setConsent] = useState(false);
  const [validConsent, setValidConsent] = useState(true);
  const [requiredConsent, setRequiredConsent] = useState(true);
  const [optionalConsent, setOptionalConsent] = useState([]);
  const [consentText, setConsentText] = useState("");
  const [baseData, setBaseData] = useState(null);
  const [form, setForm] = useState([]);
  const [actions, setAction] = useState([]);
  const [postAction, setPostAction] = useState([]);
  const [dropped, setDropped] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [sfDistrictSet, setSfDistrictData] = useState([]);
  const [sfSubDistrictSet, setSfSubDistrictData] = useState([]);

  const [occDetail, setOccDetail] = useState([]);

  const [isThaiId, setIsThaiId] = useState(true);
  const [radio, setRadio] = useState("thai_id_card");


  const [planRadio, setPlanRadio] = useState("");
  const [interestPlan, setInterestPlan] = useState("");
  const [showMotorExpireMonthField, setShowMotorExpireMonthField] =
    useState(false);

  const [transitionPlan, setTransitionPlan] = useState(true);

  const handleThaiIdRadio = (event) => {
    setRadio(event.target.value);
    if (event.target.value === "thai_id_card") {
      setIsThaiId(true);
    } else {
      setIsThaiId(false);
    }
  };


  const handlePlanRadio = (event, i) => {
    setPlanRadio(event.target.value);
    if (event.target.value === "ประกันรถยนต์") {
      setInterestPlan("ประกันรถยนต์");
      setTransitionPlan(true);
      setShowMotorExpireMonthField(true);
    } else {
      setInterestPlan(event.target.value);
      setTransitionPlan(false);
      setShowMotorExpireMonthField(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (form.length > 0) {
      const interestPlanData = form.find((e) => e.type === "INTERESTPLAN");
      if (interestPlanData !== undefined) {
        if (interestPlan !== "") {
          interestPlanData.value = [interestPlan, ""];
        }
        const newForm = form.map((e) => {
          if (e.type === "INTERESTPLAN") {
            e.value = interestPlanData.value.length === 0 ? [] : [...interestPlanData.value];
          }
          return e;
        });
        setForm(newForm);
      }
    }
  }, [interestPlan]);


  useEffect(() => {
    if (match) {
      fetchData();
    } else {
      history.replace("/");
    }
  }, []);

  const getQuery = () => {
    let data = {};
    for (const [key, value] of query) {
      data = { ...data, [key]: value };
    }

    return data;
  };
  // Pre Action
  // - IMAGE
  // - TEXT
  // - REDIRECTION

  const fetchData = async () => {
    try {
      const query = getQuery();
      const result = await FormApi.getByCode(match.params.id, query);
      document.title = result.data.header?.title ? result.data.header.title : "SCB Protect";

      if (result.data.sourceState && result.data.sourceState === "Inactive") {
        console.log("Source status is inactive");
        history.replace("/leadForm/notfound");
      }
      const actionRedirect = result.data.actions.find((item) => item.type === "REDIRECTION");
      if (actionRedirect) {
        window.location.href = actionRedirect.values;
      } else {
        setLoading(false);
      }
      setBaseData(result.data);
      if (!result.data.consent || typeof result.data.consent === "string") {
        setRequiredConsent(false);
      } else {
        setRequiredConsent(true);
        setConsentText(result.data.consent.content);
      }

      // optionalConsent
      if (result.data.hasOwnProperty("additionalConsent")) {
        const newOptConsent = result.data.additionalConsent.map((e) => {
          return {
            ...e,
            checked: false,
            isError: false,
          };
        });
        setOptionalConsent(newOptConsent);
      }
      // end optionalConsent

      setAction(result.data.actions);

      const newTemp = result.data.template.components.map((e) => {
        if (e.type === "CALENDAR") {
          let obj = {
            ...e,
            required: e.required,
            error: false,
          };
          if (e.condition_type && e.condition_type === "AGE") {
            const minDate = dayjs().add(-1 * e.max, "years");
            const maxDate = dayjs().add(-1 * e.min, "years");

            obj.min = minDate.format("YYYY-MM-DD");
            obj.max = maxDate.format("YYYY-MM-DD");
          } else {
            if (e.default) {
              obj.value = convertDate(e.default);
            } else {
              obj.value = e.value ? new Date(e.value) : "";
            }

            if (e.min) {
              obj.min = convertDate(e.min);
            }
            if (e.max) {
              obj.max = convertDate(e.max);
            }
          }

          return obj;
        }
        if (e.type === "CALENDARENG") {
          let obj = {
            ...e,
            required: e.required,
            error: false,
          };
          if (e.condition_type && e.condition_type === "AGE") {
            const minDate = dayjs().add(-1 * e.max, "years");
            const maxDate = dayjs().add(-1 * e.min, "years");

            obj.min = minDate.format("YYYY-MM-DD");
            obj.max = maxDate.format("YYYY-MM-DD");
          } else {
            if (e.default) {
              obj.value = convertDate(e.default);
            } else {
              obj.value = e.value ? new Date(e.value) : "";
            }

            if (e.min) {
              obj.min = convertDate(e.min);
            }
            if (e.max) {
              obj.max = convertDate(e.max);
            }
          }

          return obj;
        } else if (e.type === "SFAREA") {
          let obj = {
            ...e,
            required: [e.required, e.required, e.required],
            value: ["", "", ""],
            error: [false, false, false],
          };

          return obj;
        } else if (e.type === "OCCUPATION") {
          let obj = {
            ...e,
            required: [e.required, e.required],
            value: ["", ""],
            error: [false, false],
          };
          return obj;
        } else if (e.type === "INTERESTPLAN") {
          let obj = {
            ...e,
            required: e.required,
            value: ["", ""],
            error: [false, false],
          };
          return obj;
        } else if (e.type === InterestedProductV2Type) {
          return GenerateInterestedProductV2ComponentObjectForm(e)
        } else {
          let defObj = "";
          if (e.type === "DROPDOWN" && e.default !== "") {
            defObj = typeof e.default === "string" ? { id: e.default, name: e.default } : e.default;
          } else {
            defObj = e.default;
          }

          let newObj = {
            ...e,
            required: e.required,
            value: e.default ? defObj : e.value,
            error: false
          };

          if (e.type === "OTP") {
            newObj = { ...newObj, validated: false };
          }

          return newObj;
        }
      });
      setForm(newTemp);
    } catch (e) {
      console.log("err", e);
      history.replace("/leadForm/notfound");
    }
  };

  const convertDate = (value) => {
    let date = value;
    if (value) {
      if (value.toUpperCase() === "TODAY") {
        date = new Date();
      } else {
        const unit_lists = [
          "day",
          "days",
          "week",
          "weeks",
          "month",
          "months",
          "quarter",
          "quarters",
          "year",
          "years",
          "hour",
          "hours",
          "minute",
          "minutes",
          "second",
          "seconds",
          "millisecond",
          "milliseconds",
        ];
        value = value.toLowerCase();

        unit_lists.forEach((v) => {
          if (value.includes(v)) {
            let numder = value.replace(/\s/g, "").replace(v, "");
            date = dayjs().add(numder, v);
          }
        });
      }
    }
    return date;
  };

  const updateData = (value, index) => {
    const newData = [...form].map((item, nIndex) => {
      if (index === nIndex) {
        item.value = value;
        item.error = false;
      }
      return item;
    });
    setForm(newData);
  };

  //สำหรับ Dropdown ที่ข้อมูลเปลี่ยนตามข้อมูลที่เลือกข้างบน
  const updateDropDown = (value, index, slot) => {
    const newData = [...form].map((item, nIndex) => {
      if (index === nIndex) {
        item.value[slot] = value;
        item.error[slot] = false;
      }
      return item;
    });
    setForm(newData);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const canSubmit = validateForm();
    if (canSubmit) {
      try {
        let data = {};
        let consent = { id: baseData.consent._id, code: baseData.consent.code };
        for (let index = 0; index < form.length; index++) {
          const item = form[index];
          if (["IMAGE", "MODAL", "REDIRECTIMAGE"].includes(item.type)) {
            continue;
          } else if (item.type === "SFAREA") {
            // console.log('sfarea', item)
            data = {
              ...data,
              provice: item.value[0],
              district: item.value[1],
              sub_district: item.value[2],
            };
          } else if (item.type === "INTERESTPLAN") {
            data = {
              ...data,
              interest_plan: item.value[0],
              car_insurance_expired_month: item.value[1],
            };
          } else if (item.type === InterestedProductV2Type) {
            data = { ...data, ...InterestedProductFinalObjectData(item.value) }
          } else if (item.type === "OCCUPATION") {
            data = {
              ...data,
              occupation: item.value[0],
              occupationdetail: item.value[1],
            };
          } else if (item.type === "CALENDAR") {
            const date = item.value ? new Date(item.value) : null;
            console.log(date);
            const formatDate = item.value
              ? dayjs(item.value).format("YYYY-MM-DD")
              : null;
            data = { ...data, [item.name]: formatDate };
          } else if (item.type === "CALENDARENG") {
            const date = item.value ? new Date(item.value) : null;
            const formatDate = item.value
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            data = { ...data, [item.name]: formatDate };
          } else if (item.type === "CALENDAR_READONLY") {
            const date = item.value ? new Date(item.value) : null;
            const formatDate = item.value
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            data = { ...data, [item.name]: formatDate };
          } else {
            data = { ...data, [item.name]: item.value };
          }
        }

        data = { ...data, query: getQuery() };
        // setting optionalConsent
        const dataOptConsent = optionalConsent.filter((e) => e.checked).map((data) => {
          return { id: data._id, code: data.code };
        });

        // console.log('data for submit', data)
        setSubbmitting(true);
        let retry = false;

        try {
          console.log("retry", retry);
          await submitProcess(data, consent, dataOptConsent);
        } catch (e) {
          console.log("error", e);
          try {
            await submitProcess(data, consent, dataOptConsent);
          } catch (e) {
            throw e;
          }
        }
      } catch (e) {
        console.log(e);
        setSubbmitting(false);
        setOpen2(true);
      }
    } else {
      setOpen(true);
    }
  };
  const submitProcess = async (data, consent, dataOptConsent) => {
    const result = await FormApi.submitForm(
      match.params.id,
      data,
      consent,
      dataOptConsent
    );
    console.log("submit result", result.data.actions);

    if (result.data.actions.length > 0) {
      const actionRedirect = result.data.actions.find(
        (item) => item.type === "REDIRECTION"
      );
      if (actionRedirect) {
        window.location.href = actionRedirect.values;
      }
      setPostAction(result.data.actions);
    }

    setDropped(true);
    setSubbmitting(false);
  };

  const validateForm = () => {
    const validate = form.map((item) => {
      if (item.type === "PROVINCE") {
        if (item.required[0]) {
          item.error[0] = item.value[0] === "" || item.value[0] === null;
        } else {
          item.error[0] = false;
        }

        if (item.required[1]) {
          item.error[1] = item.value[1] === "" || item.value[1] === null;
        } else {
          item.error[1] = false;
        }

        if (item.required[2]) {
          item.error[2] = item.value[2] === "" || item.value[2] === null;
        } else {
          item.error[2] = false;
        }
      } else if (item.type === "SFAREA") {
        if (item.required[0]) {
          item.error[0] = item.value[0] === "" || item.value[0] === null;
        } else {
          item.error[0] = false;
        }

        if (item.required[1]) {
          item.error[1] = item.value[1] === "" || item.value[1] === null;
        } else {
          item.error[1] = false;
        }

        if (item.required[2]) {
          item.error[2] = item.value[2] === "" || item.value[2] === null;
        } else {
          item.error[2] = false;
        }
      } else if (item.type === "INTERESTPLAN") {
        // console.log('SF PROVINCE', item)
        if (item.required) {
          item.error[0] = item.value[0] === "" || item.value[0] === null;
          console.log(item.error[0]);
        } else {
          item.error[0] = false;
        }
        if (item.value[0] === "ประกันรถยนต์" && item.required) {
          item.error[1] = item.value[1] === "" || item.value[1] === null;
        } else {
          item.error[1] = false;
        }
      } else if (item.type === "OCCUPATION") {
        if (item.required[0]) {
          item.error[0] = item.value[0] === "" || item.value[0] === null;
        } else {
          item.error[0] = false;
        }
        if (item.required[1]) {
          item.error[1] = item.value[1] === "" || item.value[1] === null;
        } else {
          item.error[1] = false;
        }
      } else if (item.type === "LIMITNUMBER") {
        if (!item.value && item.required) {
          item.error = true;
        } else if (item.required) {
          const re = /^\d+$/;
          item.error =
            item.value.length < item.minLength || !re.test(item.value);
        } else {
          const re = /^\d+$/;
          item.error = !re.test(item.value);
        }
      } else if (item.type === "EMAIL") {
        if (!item.value && item.required) {
          item.error = true;
        } else {
          // eslint-disable-next-line
          let test = new RegExp(
            '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
            "g"
          );
          let valid = test.test(item.value);
          const re =
            /^(([^<>()[\]\\.,;:\s@\"]+[a-zA-Z\-0-9]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          item.error = item.value && !valid;
        }
      } else if (item.type === "MOBILE") {
        if (!item.value && item.required) {
          item.error = true;
        } else {
          // eslint-disable-next-line
          item.error =
            item.value &&
            !/[0][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/g.test(
              item.value
            );
        }
      } else if (item.type === "IDCARD") {
        if (!item.value && item.required) {
          item.error = true;
        } else {
          let reId = item.value && !thaiIdCard.verify(item.value);
          item.error = reId;
        }
      } else if (item.type === "THAIIDANDPASSPORT") {
        if (!item.value && item.required) {
          item.error = true;
        } else if (isThaiId) {
          let reId = item.value && !thaiIdCard.verify(item.value);
          item.error = reId;
        }
      } else if (item.type === "PLAINTEXT") {
        item.error = false;
      } else if (item.type === "HIDDENFIELD") {
        item.error = false;
      } else if (item.type === "OTP") {
        if (!item.validated) {
          item.error = true;
        } else {
          item.error = false;
        }
      } else if (item.type === InterestedProductV2Type) {
        item.error = !InterestedProductV2Validated(item.value, item.required)
      } else if (!item.required || (item.required && item.value)) {
        item.error = false;
      } else {
        item.error = true;
      }
      return item;
    });
    const validConsent = !requiredConsent ? true : consent;
    if (requiredConsent && !consent) {
      setValidConsent(consent);
    }
    const newOptionalConsent = optionalConsent.map((e) => {
      if (e.required && !e.checked) {
        e.isError = true;
      }
      return e;
    });
    const optionalConsentValid =
      optionalConsent.length <= 0
        ? true
        : newOptionalConsent.every((e) => !e.isError);

    setOptionalConsent(newOptionalConsent);
    setForm(validate);
    return (
      validate.every((e) =>
        e.type === "PROVINCE" ||
          e.type === "SFAREA" ||
          e.type === "OCCUPATION" ||
          e.type === "INTERESTPLAN"
          ? e.error.every((p) => !p)
          : !e.error
      ) &&
      validConsent &&
      optionalConsentValid
    );
  };

  if (dropped) {
    return (
      <div className="App">
        <HeaderLayer />
        <Container maxWidth="md" className="App-header">
          {postAction.length > 0 ? (
            postAction.sort(function (a, b) { return a.order - b.order; }).map((action, index) => {
              if (action.type === "IMAGE") {
                return action.values.map((i, j) => (
                  <img src={i} key={`${index}_${j}`} alt={`img_${j}`} style={{ width: "100%", objectFit: "contain" }} />
                ));
              } else if (action.type === "TEXT") {
                const text = Array.isArray(action.values) && action.values.length > 0 ? action.values.join() : "ลงทะเบียนเรียบร้อยแล้ว";
                return (
                  <div style={{ textAlign: "center", color: "#0486AD" }} key={index} dangerouslySetInnerHTML={{ __html: text }} ></div>
                );
              } else {
                return <div key={index}></div>;
              }
            })
          ) : (
            <div style={{ textAlign: "center" }}>
              <div style={{ textAlign: "center", color: "#0486AD", marginBottom: "20px" }}>{"ลงทะเบียนเรียบร้อยแล้ว"}</div>
              <img src={correct} alt="correct" style={{ width: "150px", height: "150px" }} />
            </div>
          )}
        </Container>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Loading />
        <HeaderLayer />
        {loading ? (
          <Grid>
            <div className="content pt-5rem"><CircularProgress /><br /></div>
            <div className="content pt-2rem"> กำลังโหลดข้อมูล</div>
          </Grid>
        ) : (
          <Container maxWidth="md" className="App-header">
            {actions.map((action, index) => {
              if (action.type === "IMAGE") {
                return action.values.map((i, j) => (
                  <img src={i} key={`${index}_${j}`} alt={`img_${j}`} style={{ width: "100%", objectFit: "contain" }} />
                ));
              } else if (action.type === "TEXT") {
                return (<div style={{ textAlign: "center" }} key={index}>{action.values}</div>);
              } else if (action.type === "MODAL") {
                return <Modal image={action.image} text={action.text} modalImage={action.modal.image} modalText={action.modal.text} />
              } else {
                return <div key={index}></div>;
              }
            })}
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {baseData?.template.type === "FORM" ? (<FormHeader headerText="กรุณากรอกข้อมูล (Registration)" />) : null}
              <div className="pb-form"></div>
              {form.map((ele, index) => {
                if (ele.type === "TEXT") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputText
                        required={ele.required}
                        autoComplete={ele.autoComplete}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        maxLength={ele.maxLength}
                        minLength={ele.minLength}
                        helperText={ele.error_msg}
                        onChange={(e) => {
                          updateData(e.target.value, index);
                        }}
                      />
                    </div>
                  );
                } else if (ele.type === "TEXT_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputTextReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "PLAINTEXT") {
                  return (
                    <div className="pb-form" key={index}>
                      <PlainText key={index} text={ele.label} />
                    </div>
                  );
                } else if (ele.type === "MOBILE") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputMobile
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        onChange={(e) => { updateData(e.target.value, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "MOBILE_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputMobileReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "CALENDAR") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputCalendar
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        min={ele.min}
                        max={ele.max}
                        helperText={ele.error_msg}
                        onChange={(e) => {
                          if (ele.min && ele.max) {
                            const isPass = dayjs(e.$d).isBetween(ele.min, ele.max);
                            console.log('minDate', ele.min)
                            console.log('maxDate', ele.max)
                            console.log('isPass', isPass)
                            if (isPass) {
                              updateData(e.$d, index)
                            } else {
                              updateData(null, index)
                            }
                          } else {
                            updateData(e.$d, index)
                          }
                        }}
                      />
                    </div>
                  );
                } else if (ele.type === "CALENDARENG") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputCalendarEng
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        min={ele.min}
                        max={ele.max}
                        helperText={ele.error_msg}
                        onChange={(e) => { updateData(e.$d, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "CALENDAR_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputCalendarReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        min={ele.min}
                        max={ele.max}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "GENDER") {
                  return (
                    <div className="pb-form" key={index}>
                      <GenderDropDown
                        required={ele.required}
                        error={ele.error}
                        onChange={(value) => { updateData(value, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "HIDDENFIELD") {
                  return (
                    <div style={{ height: "0px" }} key={index}>
                      <HiddenField
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "EMAIL") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputEmail
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        onChange={(e) => { updateData(e.target.value, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "EMAIL_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputEmailReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "IDCARD") {
                  return (
                    <div className="pb-form" key={index}>
                      <IdCard
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        onChange={(e) => { updateData(e.target.value, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "IDCARD_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <IdCardReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                      />
                    </div>
                  );
                } else if (ele.type === "TIME") {
                  return (
                    <div className="pb-form" key={index}>
                      <BasicDropdown
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        dataset={contact_itme_list.map((e) => ({ id: e, name: e }))}
                        onChange={(e) => { updateData(e.target.value, index); }}
                      />
                    </div>
                  );
                } else if (ele.type === "DROPDOWN") {
                  return (
                    <div className="pb-form" key={index}>
                      <BasicDropdown
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        dataset={ele.dataset.map((e) => {
                          if (typeof e === "string") return { id: e, name: e };
                          else return { ...e, id: e.id, name: e.name };
                        })}
                        onChange={(e, value) => { updateData(value, index) }}
                      />
                    </div>
                  );
                } else if (ele.type === "DROPDOWN_READONLY") {
                  return (
                    <div className="pb-form" key={index}>
                      <BasicDropdownReadOnly
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        dataset={ele.dataset.map((e) => {
                          if (typeof e === "string") return { id: e, name: e };
                          else return { ...e, id: e.id, name: e.name };
                        })}
                      />
                    </div>
                  );
                } else if (ele.type === "SFAREA") {
                  return (
                    <div key={index}>
                      <div className="pb-form">
                        <BasicDropdown
                          required={ele.required[0]}
                          value={ele.value[0]}
                          error={ele.error[0]}
                          label={"จังหวัด"}
                          helperText={"กรุณาเลือกจังหวัด"}
                          dataset={sfAreaData}
                          onChange={(e, item) => {
                            if (item) {
                              updateDropDown({ id: item.id, name: item.name }, index, 0);
                              updateDropDown("", index, 1);
                              updateDropDown("", index, 2);
                              setSfDistrictData(item.dataset);
                              setSfSubDistrictData([]);
                            }
                          }}
                        />
                      </div>
                      <div className="pb-form">
                        <BasicDropdown
                          required={ele.required[1]}
                          value={ele.value[1]}
                          error={ele.error[1]}
                          label={"เขต/อำเภอ"}
                          helperText={"กรุณาเลือกเขต/อำเภอ"}
                          dataset={sfDistrictSet}
                          onChange={(e, item) => {
                            if (item) {
                              updateDropDown({ id: item.id, name: item.name }, index, 1);
                              updateDropDown("", index, 2);
                              setSfSubDistrictData(item.dataset);
                            }
                          }}
                        />
                      </div>
                      <div className="pb-form">
                        <BasicDropdown
                          required={ele.required[2]}
                          value={ele.value[2]}
                          error={ele.error[2]}
                          label={"แขวง/ตำบล"}
                          helperText={"กรุณาเลือกแขวง/ตำบล"}
                          dataset={sfSubDistrictSet}
                          onChange={(e, item) => {
                            if (item) {
                              updateDropDown({ id: item.id, name: item.name }, index, 2);
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                } else if (ele.type === "OCCUPATION") {
                  return (
                    <div key={index}>
                      <div className="pb-form">
                        <BasicDropdown
                          required={ele.required[0]}
                          value={ele.value[0]}
                          error={ele.error[0]}
                          label={"อาชีพ"}
                          helperText={"กรุณาเลือกอาชีพ"}
                          dataset={occupationData}
                          onChange={(e, item) => {
                            if (item) {
                              updateDropDown({ id: item.id, name: item.name }, index, 0);
                              updateDropDown("", index, 1);
                              setOccDetail(item.dataset);
                            }
                          }}
                        />
                      </div>
                      <div className="pb-form">
                        <BasicDropdown
                          required={ele.required[1]}
                          value={ele.value[1]}
                          error={ele.error[1]}
                          label={"ลักษณะอาชีพ"}
                          helperText={"กรุณาเลือกลักษณะอาชีพ"}
                          dataset={occDetail}
                          onChange={(e, item) => {
                            if (item) updateDropDown({ id: item.id, name: item.name }, index, 1);
                          }}
                        />
                      </div>
                    </div>
                  );
                } else if (ele.type === "IMAGE") {
                  return (
                    <div className="pb-form" key={index}>
                      <img
                        src={ele.value}
                        alt={ele.label}
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    </div>
                  );
                } else if (ele.type === "REDIRECTIMAGE") {
                  return (
                    <div className="pb-form" key={index}>
                      <img
                        src={ele.value}
                        alt={ele.label}
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(ele.redirect, "_blank");
                        }}
                      />
                    </div>
                  );
                } else if (ele.type === "MODAL") {
                  return (
                    <div className="pb-form" key={index}>
                      <Modal
                        image={ele.image}
                        text={ele.text}
                        modalImage={ele.modal.image}
                        modalText={ele.modal.text}
                      />
                    </div>
                  );
                } else if (ele.type === "OTP") {
                  return (
                    <div className="pb-form">
                      <Otp
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={
                          "กรุณากดปุ่ม ขอ OTP เพื่อยืนยันตัวตนก่อนส่งข้อมูลของท่าน"
                        }
                        onChangeValid={(flag) => {
                          const newData = [...form].map((item, nIndex) => {
                            if (index === nIndex) {
                              item.validated = flag;
                              item.error = false;
                            }
                            return item;
                          });
                          setForm(newData);
                        }}
                        onChange={(e) => {
                          updateData(e.target.value, index);
                        }}
                      />
                    </div>
                  );
                } else if (ele.type === "THAIIDANDPASSPORT") {
                  return (
                    <div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={radio}
                          row
                          onChange={handleThaiIdRadio}
                        >
                          <FormControlLabel
                            value="thai_id_card"
                            control={<Radio />}
                            label="เลขบัตรประชาชน (ID Card)"
                          />
                          <FormControlLabel
                            value="passport"
                            control={<Radio />}
                            label="เลขพาสปอร์ต (Passport No.)"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div></div>
                      <div className="pb-form" key={index}>
                        {isThaiId ? (
                          <IdCard
                            required={ele.required}
                            label={ele.label}
                            value={ele.value}
                            error={ele.error}
                            helperText={ele.error_msg}
                            onChange={(e) => {
                              updateData(e.target.value, index);
                            }}
                          />
                        ) : (
                          <InputText
                            required={ele.required}
                            label={ele.label}
                            value={ele.value}
                            error={ele.error}
                            helperText={ele.error_msg}
                            onChange={(e) => {
                              updateData(e.target.value, index);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                } else if (ele.type === "INTERESTPLAN") {
                  return (
                    <div>
                      <p
                        style={
                          ele.error[0]
                            ? { fontSize: "18px", marginTop: "10px", marginBottom: "0px", color: "red" }
                            : { fontSize: "18px", marginTop: "10px", marginBottom: "0px", color: "black" }
                        }
                      >
                        {ele.required ? "ความคุ้มครองที่คุณสนใจ*" : "ความคุ้มครองที่คุณสนใจ"}
                      </p>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="plan"
                          name="plan"
                          value={planRadio}
                          onChange={(e, value) => {
                            handlePlanRadio(e, value);
                          }}
                        >
                          <FormControlLabel
                            value="ประกันรถยนต์"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันรถยนต์"
                          />
                          <FormControlLabel
                            value="ประกันลดหย่อนภาษี (สะสมทรัพย์/เกษียณ/บำนาญ)"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันลดหย่อนภาษี (สะสมทรัพย์/เกษียณ/บำนาญ)"
                          />
                          <FormControlLabel
                            value="ประกันชีวิต"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันชีวิต"
                          />
                          <FormControlLabel
                            value="ประกันสุขภาพและโรคร้ายแรง"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันสุขภาพและโรคร้ายแรง"
                          />
                          <FormControlLabel
                            value="ประกันอุบัติเหตุ"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันอุบัติเหตุ"
                          />
                          <FormControlLabel
                            value="ประกันเดินทาง"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันเดินทาง"
                          />
                          <FormControlLabel
                            value="ประกันบ้านและทรัพย์สิน"
                            control={<Radio color="#6F42CE" />}
                            label="ประกันบ้านและทรัพย์สิน"
                          />

                        </RadioGroup>
                      </FormControl>
                      <div></div>
                      <div key={index}>
                        <Zoom
                          in={transitionPlan}
                          style={{ transitionDelay: "0ms" }}
                        >
                          <div
                            className={
                              showMotorExpireMonthField
                                ? `pb-form`
                                : "display-none"
                            }
                          >
                            <BasicDropdown
                              required={ele.required}
                              value={
                                showMotorExpireMonthField ? ele.value[1] : ""
                              }
                              error={ele.error[1]}
                              label={"ประกันรถยนต์ของคุณหมดเดือนไหน"}
                              helperText={
                                "กรุณาเลือกประกันรถยนต์ของคุณหมดเดือนไหน"
                              }
                              dataset={[
                                { id: "มกราคม", name: "มกราคม" },
                                { id: "กุมภาพันธ์", name: "กุมภาพันธ์" },
                                { id: "มีนาคม", name: "มีนาคม" },
                                { id: "เมษายน", name: "เมษายน" },
                                { id: "พฤษภาคม", name: "พฤษภาคม" },
                                { id: "มิถุนายน", name: "มิถุนายน" },
                                { id: "กรกฎาคม", name: "กรกฎาคม" },
                                { id: "สิงหาคม", name: "สิงหาคม" },
                                { id: "กันยายน", name: "กันยายน" },
                                { id: "ตุลาคม", name: "ตุลาคม" },
                                { id: "พฤศจิกายน", name: "พฤศจิกายน" },
                                { id: "ธันวาคม", name: "ธันวาคม" },
                              ]}
                              onChange={(e, value) => {
                                updateDropDown(value, index, 1);
                              }}
                            />
                          </div>
                        </Zoom>
                      </div>
                    </div>
                  );
                } else if (ele.type === "LIMITNUMBER") {
                  return (
                    <div className="pb-form" key={index}>
                      <InputNumber
                        required={ele.required}
                        label={ele.label}
                        value={ele.value}
                        error={ele.error}
                        helperText={ele.error_msg}
                        maxLength={ele.maxLength}
                        minLength={ele.minLength}
                        onChange={(e) => {
                          updateData(e.target.value, index);
                        }}
                      />
                    </div>
                  );
                } else if (ele.type === InterestedProductV2Type) {
                  return <div className="pb-form" key={index}>
                    <InterestProductV2
                      required={ele.required}
                      value={ele.value}
                      error={ele.error}
                      onChange={(v) => { updateData(v, index); }} />
                  </div>
                } else {
                  return null;
                }
              })}
              {requiredConsent && baseData?.template.type === "FORM" ? (
                <div className={`pb-form ${validConsent ? "" : "error"}`}>
                  <CheckBox
                    text={consentText}
                    value={consent}
                    onChange={(e) => {
                      setConsent(e.target.checked);
                      setValidConsent(true);
                    }}
                  />
                </div>
              ) : null}
              {optionalConsent.map((e, index) => (
                <div className={`pb-form ${e.isError ? "error" : ""}`}>
                  <CheckBox
                    text={e.content}
                    value={consent}
                    onChange={(e) => {
                      const list = [...optionalConsent];
                      list[index].checked = e.target.checked;
                      list[index].isError = false;
                      setOptionalConsent(list);
                    }}
                  />
                </div>
              ))}
              {baseData?.template.type === "FORM" ? (
                <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
                  <LmsButton text="ส่งข้อมูล" />
                </div>
              ) : null}
            </form>
          </Container>
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClose2} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {subbmitting ? (
          <div className="submit-load">
            <CircularProgress />
          </div>
        ) : null}
      </div>
    );
  }
}

export default App;
