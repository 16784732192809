import React from 'react'
import './FormHeader.css'

const FormHeader = ({ headerText }) => {
    return (
        <>
            <div className="header-text">
                {headerText}
            </div>
        </>
    )
}

export default FormHeader