import React, { useState } from 'react'
import './Modal.css'

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

const style = {
    margin: '0 auto',
    // position: 'absolute',
    // top: '30px',
    // left: '50%',
    // height: '100vh',
    // transform: 'translate(-50%, 0%)',
    width: '80%',
    bgcolor: 'background.paper',
    backgroundColor: 'white',
    padding: '20px',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    outline: 'none',
    marginTop: '20px',
    marginBottom: '20px'
};


const ModalCustom = ({ image, text, modalImage, modalText }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="modal-click" onClick={() => setOpen(true)}>
                <img src={image} alt={image} style={{ width: '100%', objectFit: 'contain' }} />
                <Typography>
                    <div dangerouslySetInnerHTML={{ __html: text }}></div>
                </Typography>
            </div>
            {/* <Button >Open modal</Button> */}
            <Modal
                className="base-modal"
                disableAutoFocus={true}
                open={open}
                onClose={() => setOpen(false)}
            // disableScrollLock={false}
            >
                <Box style={style}>
                    <img src={modalImage} alt={modalImage} style={{ width: '100%', objectFit: 'contain' }} />
                    <div className="modal-text" dangerouslySetInnerHTML={{ __html: modalText }}></div>
                </Box>
            </Modal>

        </>
    )
}

export default ModalCustom