import React from 'react'
import './LmsButton.css'

const LmsButton = ({ text,  onClick = () => { } }) => {
    return (
        <button className="lms-btn" onClick={onClick} >
            {text}
        </button>
    )
}

export default LmsButton