import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  loading: false,
  access_token: null,
  refresh_token: null,
  projectList: []
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'loading-on': {
      return { ...state, loading: true }
    }
    case 'loading-off': {
      return { ...state, loading: false }
    }
    default:
      return state
  }
}

const store = createStore(changeState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store