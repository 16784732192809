import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux'


const Loading = () => {
    const loading = useSelector(e => e.loading)
    return (loading ?
        <div style={{ position: 'fixed', index: '1000 !important', width: '100vw', height: '100vh', backgroundColor: '#9c9a9a57', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
            <CircularProgress /><br />
        </div>
        : null
    )
}

export default Loading