import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import BasicDropdown from '../BasicDropdown/BasicDropdown';
import InputText from '../InputText/InputText';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CarBrand from '../../data/carBrand.json'
import ExpiryMonth from '../../data/expiryMonth.json'
import ProductList from '../../data/productList.json'

function InterestedProductV2({ required, value = [[], '', '', ''], onChange, error }) {
    const [carBrand, setCarBrand] = React.useState('')
    const [expiryMonth, setExpiryMonth] = React.useState('')

    const handleData = (newValue, index) => {
        const newData = [...value]
        newData[index] = newValue

        if (!newData[0].includes('ประกันรถยนต์')) {
            newData[1] = ''
            newData[2] = ''
            newData[3] = ''
        }
        onChange(newData)
    }

    const handleChange = (event) => {
        let vals = value[0]
        if (event.target.checked) {
            vals.push(event.target.name)
        } else {
            vals = vals.filter(e => e !== event.target.name)
        }
        handleData(vals, 0)
    };

    const handleCarBrand = (event, data) => {
        handleData(data?.id || null, 1)
    }

    const handleCarModel = (event) => {
        handleData(event.target.value, 2)
    }

    const handleExpiryMonth = (event, data) => {
        handleData(data?.id || null, 3)
    }

    const Required = () => {
        return value[0].includes('ประกันรถยนต์')
    }

    React.useEffect(() => {
        const targetCarBrand = CarBrand.find(e => e.id === value[1])
        const targetExpiryMonth = ExpiryMonth.find(e => e.id === value[3])
        setCarBrand(targetCarBrand)
        setExpiryMonth(targetExpiryMonth)
    }, [value])


    return (
        <FormControl component="fieldset" style={{ width: '100%', paddingTop: '20px' }}>
            <div className='set-font' style={{ fontSize: '18px', color: error ? 'red' : 'black' }} >สนใจจ่ายเบี้ยประกันถูกลงมั้ย? ให้เราเปรียบเทียบเบี้ยที่คุ้มค่าที่สุดให้คุณ (เลือกได้มากกว่า 1)
                {required ? " *" : ""} </div>
            <FormGroup>
                {ProductList.map((product, index) => {
                    return (
                        <>
                            <FormControlLabel
                                control={<Checkbox checked={value[0].includes(product)} onChange={handleChange} name={product} />}
                                label={product}
                            />
                            {product === 'ประกันรถยนต์' && Required() && <div className='pl-50'>
                                <div className='set-font-size set-font'> โปรดระบุรายละเอียดรถยนต์ของท่าน</div>
                                <div className="pb-form">
                                    <BasicDropdown
                                        required={Required()}
                                        label={'ยี่ห้อรถ'}
                                        dataset={CarBrand}
                                        value={carBrand}
                                        onChange={handleCarBrand}
                                    />
                                </div>
                                <div className="pb-form">
                                    <InputText required={Required()} label={'รุ่นรถ'} value={value[2]} onChange={handleCarModel} />
                                </div>
                                <div className="pb-form">
                                    <BasicDropdown
                                        required={Required()}
                                        label={'ประกันหมดอายุเดือนไหน'}
                                        dataset={ExpiryMonth}
                                        value={expiryMonth}
                                        onChange={handleExpiryMonth}
                                    />
                                </div>
                            </div>}
                        </>
                    )
                }
                )}
            </FormGroup>
        </FormControl>
    )
}

export const InterestedProductV2Type = "INTERESTEDPRODUCT"


const carVerified = (v1, v2, v3) => {
    const carBrand = CarBrand.map(e => e.id).includes(v1)
    const carModel = v2 !== null && v2 !== ""
    const expiryMonth = ExpiryMonth.map(e => e.id).includes(v3)
    return carBrand && carModel && expiryMonth
}

export const InterestedProductV2Validated = (value, required) => {
    if (required) {
        if (value[0].length <= 0) return false

        if (value[0].includes('ประกันรถยนต์')) {
            return carVerified(value[1], value[2], value[3])
        }
    } else {
        if (value[0].length > 0 && value[0].includes('ประกันรถยนต์')) {
            return carVerified(value[1], value[2], value[3])
        }
    }

    return true
}

export const GenerateInterestedProductV2ComponentObjectForm = (ele) => {
    return {
        ...ele,
        value: [[], "", "", ""],
        error: false,
    }
}

export const InterestedProductFinalObjectData = (value) => {
    const obj = {
        interested_product: value[0]
    }

    if (obj.interested_product.includes('ประกันรถยนต์')) {
        obj.car_brand = value[1];
        obj.car_model = value[2];
        obj.car_expiry_month = value[3];
    }

    return obj
}

export default InterestedProductV2