import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: "#7B25F6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6F42CE",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#6F42CE",
    },
    "& .MuiInputBase-root::before": {
      border: "none",
      borderBottomColor: "#6F42CE",
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#7B25F6",
      },
    },
  },
})(TextField);

const InputTextReadOnly = ({
  required,
  label,
  value,
  error,
  helperText,
}) => {
  return (
    <>
      <CssTextField
        required={required}
        label={label}
        value={value}
        error={error}
        helperText={error ? helperText : ""}
        variant="filled"
        InputProps={{
          readOnly: true,
        }}
      />
    </>
  );
};

export default InputTextReadOnly;
