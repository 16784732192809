import axios from 'axios';

// const apiKey = '7a6f695e-2a66-40da-94f6-ad4d613ca6f9'




const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_URL
    } else {
        console.log('window.ENV', window.ENV)
        return window.ENV.REACT_APP_API_URL
    }
}
const baseurl = getBaseUrl()

const getLMSApiKey = () => {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_LMS_API_KEY
    } else {
        console.log('window.ENV', window.ENV)
        return window.ENV.REACT_APP_LMS_API_KEY
    }
}
const apiKey = getLMSApiKey()

//get otp
const requestOTP = async (phone,sourceid) => {
    return await axios.post(`${baseurl}/otp/request/${sourceid}`,
        { "phone": phone },
        {
            headers: {
                'x-api-key': apiKey
            }
        })
}

//validate otp
const validate = async (phone,ref,otp,sourceid) => {
    return await axios.post(`${baseurl}/otp/verify/${sourceid}`,
        {
            "phone": phone,
            "refCode": ref,
            "otp": otp
        },
        {
            headers: {
                'x-api-key': apiKey
            }
        })
}

//resend otp
const resend = async (phone,ref,sourceid)=>{
    return await axios.post(`${baseurl}/otp/resend/${sourceid}`,
        {
            "phone": phone,
            "ref": ref,
        },
        {
            headers: {
                'x-api-key': apiKey
            }
        })
}
export default { requestOTP, validate ,resend}