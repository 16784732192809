import axios from 'axios';

const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'https://sit-leadapi.scbprotect.io/digital-platform-lead-management-api/v2'
    } else {
        console.log('window.ENV', window.ENV)
        return window.ENV.REACT_APP_API_URL
    }
}

const getByCode = async (id, query) => {
    console.log('url', getBaseUrl())
    let baseUrl = `${getBaseUrl()}/lead/preprocessing/source/${id}`
    const qString = new URLSearchParams(query).toString()
    if (qString) {
        baseUrl += '?' + qString
    }

    return await axios.get(baseUrl);
}

const getConsent = async (id) => {
    return await axios.get(`${getBaseUrl()}/source/lite/${id}`);
}

const submitForm = async (id, data, consent, optionalConsent) => {
    return await axios.post(`${getBaseUrl()}/lead/source/${id}`, { data: data, consent: consent, additionalConsent: optionalConsent });
}

const func = {
    getByCode,
    submitForm,
    getConsent
}

export default func

    //https://leadapi.scbprotect.io
   // / preprocessing / source / 6046f20ee44d250006c18ee6
   //604871676eb0a90006c085eb