import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import MaskedInput from 'react-text-mask';
// import emailMask from 'text-mask-addons/dist/emailMask'


const CssTextField = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#7B25F6',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiInputBase-root::before': {
            border: 'none',
            borderBottomColor: '#6F42CE'
        },
        '& .MuiFilledInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336 !important'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7B25F6',
            },
        },
    },
})(TextField);

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <CssTextField
            {...other}
            style={{ width: '100%' }}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            // guide={false}
            // showMask={false}
            // mask={emailMask}
            placeholderChar={'\u2000'}
        />
    );
}

const InputEmailReadOnly = ({ required, label, value, error, helperText }) => {
    return (
        <>
            <CssTextField
                required={required}
                label={label}
                value={value}
                error={error}
                helperText={error ? helperText : ''}
                variant="filled"
                id={label}
                InputProps={{
                    // inputComponent: TextMaskCustom,
                    readOnly: true,
                }}

            />
        </>
    )
}


export default InputEmailReadOnly