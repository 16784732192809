import React from 'react'
import './PlainText.css'

const PlainText = ({ text,tooltip }) => {
    return (
    <>
    <div className="text-custom " dangerouslySetInnerHTML={{ __html: text }}>
    </div>
    {/* <div>

    </div> */}
    </>
    )
}

export default PlainText