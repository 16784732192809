import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import dayJS from 'dayjs';
var isBetween = require('dayjs/plugin/isBetween')
dayJS.extend(isBetween)

const CssTextField2 = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: '#7B25F6',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#6F42CE',
        },
        '& .MuiFilledInput-underline.Mui-error:after': {
            borderBottomColor: '#f44336 !important'
        },
        '& .MuiInputBase-root::before': {
            border: 'none',
            borderBottomColor: '#6F42CE'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7B25F6',
            },
        },
    },
})(DatePicker);


const InputCalendar = ({ required, label, value, error, onChange, helperText, max, min }) => {

    const renderLabel = (date) => {
        console.log(value)
        if (value && date.isValid()) {
            return date.format('DD/MM/YYYY');
        } else {
            return 'วว/ดด/ปปปป (DD/MM/YYYY)';
        }
    }


    const handleOnChange = (e) => {
        onChange(e)
    }

    return (
        <>
            <CssTextField2
                initialFocusedDate={false}
                required={required}
                label={label}
                minDate={min ? new Date(min) : new Date(dayJS().add(-100, 'years').format('YYYY-MM-DD'))}
                maxDate={max ? new Date(max) : new Date('2200-12-31')}
                value={value}
                onChange={handleOnChange}
                error={error}
                helperText={error ? helperText : ''}
                inputVariant="filled"
                format="DD/MM/YYYY"
                openTo={"year"}
                views={['year', 'month', 'date']}
                labelFunc={renderLabel}
            />
        </>
    )
}

export default InputCalendar