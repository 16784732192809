import './App.css';
import React from 'react'
import Container from '@material-ui/core/Container';
import HeaderLayer from './components/HeaderLayer/HeaderLayer';

function App() {
    return (
        <div className="App">
            <HeaderLayer />
            <Container maxWidth="md" className="App-header">
                <div className='content pt-5rem'>
                    ไม่พบหน้าที่คุณค้นหา
                </div>
            </Container>
        </div>
    );
}

export default App;
