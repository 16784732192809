import '../App.css'
import React from "react";
import InterestPlanV2, { InterestedProductV2Validated, GenerateInterestedProductV2ComponentObjectForm, InterestedProductFinalObjectData } from "../components/InterestedProductV2";
import Container from "@material-ui/core/Container";

const json = {
    "type": "INTERESTEDPRODUCT",
    "name": "interested_product",
    "required": true,
    "value": [
        [],
        "",
        "",
        ""
    ],
    "default": "",
    "error_msg": ""
}

function Page() {
    const [form, setForm] = React.useState([])
    const [error, isError] = React.useState(false)

    React.useEffect(() => {
        let form = [GenerateInterestedProductV2ComponentObjectForm(json)]
        setForm(form)
    }, [])

    React.useEffect(() => {
        if (form.length > 0)
            isError(!InterestedProductV2Validated(form[0].value, form[0].required))

    }, [form])

    const updateData = (value, index) => {
        console.log('updateData', value, index)
        const newData = [...form].map((item, nIndex) => {
            if (index === nIndex) {
                item.value = value;
                item.error = false;
            }
            return item;
        });

        console.log('newData', newData)
        setForm(newData)
    };
    return (
        <Container maxWidth="md" className="App-header">
            {form.map((e, index) =>
                <div className="pb-form">
                    <InterestPlanV2
                        required={e.required}
                        value={e.value}
                        error={e.error}
                        onChange={(v) => {
                            updateData(v, index);
                        }} />
                </div>
            )}
            <div>
                Is Error :  {error ? 'true' : 'false'}
            </div>
            <div>
                Final Object : {form.length > 0 ? JSON.stringify(InterestedProductFinalObjectData(form[0].value)) : '-'}
            </div>
        </Container>
    )
}

export default Page