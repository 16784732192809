import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MaskedInput from '@biproxi/react-text-mask';
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
// import Loading from "../Loading/index";
import smsService from "../../api/SmsApi";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";




const style = {
  margin: "0 auto",
  width: "300px",
  // position: 'absolute',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "SukhumvitSet,sans-serif",
  // top: '30px',
  // left: '50%',
  // height: '100vh',
  // transform: 'translate(-50%, 0%)',
  maxWidth: "80%",
  bgcolor: "background.paper",
  backgroundColor: "white",
  padding: "20px",
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  outline: "none",
  // marginTop: '20px',
  // marginBottom: '20px'
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    height: "56px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    alignSelf: "center",
    // padding: 10,
    width: "15%",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const CssTextField = withStyles({
  root: {
    width: "85%",
    borderTopRightRadius: 0,
    "& .MuiFilledInput-root": {
      borderTopRightRadius: 0,
    },
    "& label.Mui-focused": {
      color: "#7B25F6",
      borderTopRightRadius: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
    },
    "& .MuiInputBase-root::before": {
      border: "none",
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336 !important",
      borderTopRightRadius: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#7B25F6",
      },
    },
  },
})(TextField);

const CssTextField2 = withStyles({
  root: {
    width: "100%",
    borderTopRightRadius: 0,
    "& .MuiFilledInput-root": {
      // borderTopRightRadius: 0,
    },
    "& label.Mui-focused": {
      color: "#7B25F6",
      borderTopRightRadius: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
    },
    "& .MuiInputBase-root::before": {
      border: "none",
      borderBottomColor: "#6F42CE",
      borderTopRightRadius: 0,
      padding: 10,
    },
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336 !important",
      borderTopRightRadius: 0,
    },
    "& .MuiFilledInput-input": {
      padding: 10,
      textAlign: "center",
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: 0,
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#7B25F6",
      },
    },
  },
})(TextField);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      style={{ width: '100%' }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />

  );
}

function TextMaskCustom2(props) {
  const { inputRef, ...other } = props;
  return (
    <CssTextField
      {...other}
      style={{ width: "100%" }}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
}

function convertTimes(time) {
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  return str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
}


const InputMobile = ({
  required,
  label,
  value,
  error,
  onChange,
  helperText,
  onChangeValid,
}) => {
  const classes = useStyles();
  const disabledBtn = () => {
    const data = value ? !(value.replace(/-/g, "").trim().length === 10) : true;
    return data;
  };

  let match = useRouteMatch("/:id");
  const [open, setOpen] = useState(false);
  const [otp, setOTP] = useState(null);
  const dispatch = useDispatch();
  const [ref, setRef] = useState("");
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [disButton, setDisButton] = useState(false);
  const [verifyphone, setVerifyPhone] = useState("")

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timer = seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <>

      <CssTextField
        required={required}
        label={label}
        value={value}
        error={error}
        onChange={onChange}
        helperText={error ? helperText : ""}
        variant="filled"
        id={label}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
      />
      <Button
        disabled={disabledBtn() || seconds > 0}
        variant="contained"
        color="primary"
        id="requestotp"
        className={classes.iconButton}
        aria-label="directions"
        onClick={async () => {
          dispatch({ type: "loading-on" });
          const phone = value.replace(/-/g, "").trim();
          setVerifyPhone(phone)
          try {
            if (match) {
              setDisButton(true)
              const result = await smsService.requestOTP(phone, match.params.id);
              if (result.status === 200) setRef(result.data.data.ref);
              console.log(result)
              dispatch({ type: "loading-off" });
              setOpen(true);
              setSeconds(120); //set interval 120 second
            }
          } catch (e) {
            dispatch({ type: "loading-off" });
            console.log("error", e);
          }
        }}
      >
        ขอ OTP
      </Button>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="base-modal"
        disableAutoFocus={true}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box style={style}>
          <div style={{ marginLeft: "auto" }}>
            <Button className="close-btn" type="button"
              style={{
                backgroundColor: "white",
                borderColor: "black",
                color: "black",
                fontWeight: "1000",
                padding: "center",

              }} onClick={() => setOpen(false)}>
              X
            </Button>
          </div>

          <div style={{ marginTop: "10px" }}>
            OTP ได้ส่งไปที่หมายเลข {value}
          </div>
          <div style={{ marginTop: "10px" }}>
            <b>Ref : {ref}</b>
          </div>
          <div style={{ marginTop: "10px" }}>
            <CssTextField2
              value={otp}
              variant="filled"
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              id={label}
              InputProps={{
                inputComponent: TextMaskCustom2,
              }}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button

              variant="contained"
              color="primary"
              onClick={async () => {
                // var elem = document.getElementById("requestotp");
                dispatch({ type: "loading-on" });
                try {
                  if (match) {
                    const result = await smsService.validate(verifyphone, ref, otp, match.params.id);
                    console.log(result)
                    dispatch({ type: "loading-off" });

                    if (result.status === 200) {
                      setOpen(false);
                      setOpenSuccess(true);
                    } else {
                      setErrorMsg(result.data.message);
                      setOpenError(true);
                    }
                  }
                } catch (e) {
                  dispatch({ type: "loading-off" });
                  setErrorMsg("มีบางอย่างผิดพลาด โปรดลองอีกครั้ง");
                  setOpenError(true);
                  console.log("error", e);
                }
              }}
            >
              ส่ง
            </Button>

            <Button
              style={{ marginLeft: "15px" }}
              disabled={seconds > 0 ? true : false}
              variant="contained"
              color="primary"
              onClick={async () => {
                dispatch({ type: "loading-on" });
                try {
                  if (match) {
                    setSeconds(120)
                    setDisButton(false)
                    const result = await smsService.resend(verifyphone, ref,match.params.id);
                    console.log(result)
                    dispatch({ type: "loading-off" });

                    if (result.status === 200) {
                      setOpen(true);
                    } else {
                      setErrorMsg(result.data.message);
                      setOpenError(true);
                    }
                  }
                } catch (e) {
                  dispatch({ type: "loading-off" });
                  setErrorMsg("มีบางอย่างผิดพลาด โปรดลองอีกครั้ง");
                  setOpenError(true);
                  console.log("error", e);
                }
              }}
            >
              {seconds > 0 ? `ส่งใหม่ได้ใน ${convertTimes(seconds)}` : "ส่ง otp อีกรอบ"}
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        disableBackdropClick
        disableEscapeKeyDown
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="base-modal"
        disableAutoFocus={true}
        open={openError}
        onClose={() => setOpenError(false)}
      >
        <Box style={style}>
          <div style={{ marginTop: "10px" }}>{errorMsg}</div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setErrorMsg("");
                setOpenError(false);
              }}
            >
              ยืนยัน
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        disableBackdropClick
        disableEscapeKeyDown
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="base-modal"
        disableAutoFocus={true}
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
      >
        <Box style={style}>
          <div style={{ marginTop: "10px" }}>ยืนยันตัวตนสำเร็จ</div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onChangeValid(true);
                setOpenSuccess(false);
              }}
            >
              ยืนยัน
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InputMobile;
